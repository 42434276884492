.container-froggy {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    .container-canvas {
        position: relative;
        width: 100%;
        max-width: 550px;
        height: max-content;
        margin: auto;
        box-sizing: border-box;
        display: flex;
        justify-content: center;

        .canvas-froggy-jump {
            width: 100%;
            margin: auto;
            aspect-ratio: 1;


            &.pointer {
                cursor: pointer;
            }
        }

        .information {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            position: absolute;
            bottom: 5px;
            left: 5px;
        }
    }

    .title {
        width: 100%;
        max-width: 550px;
        margin: auto;
        background-image: url("./images/fondo2.png");
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;

        h3 {
            margin: 10px;
            color: white;
            text-align: center;
        }
    }


}