/* READSPEAKER */

.readspeaker_area {
    width: unset !important;
}

.readspeaker_area .rs_addtools,
.rsbtn {
    margin-bottom: unset !important;
}
.readspeaker_area
    .rsbtn
    .rsbtn_exp
    .rsbtn_exp_inner
    .rsbtn_player_item:first-child {
    border-left: unset !important;
}
/* bar when reading out loud */
.readspeaker_area .rsbtn .rs-player-loadindicator {
    left: unset !important;
    top: 28px !important;
    width: 252px;
    border-radius: 8px;
}
/* controls when reading out loud (adjust speed or volume) */
.readspeaker_area .rsbtn .rsbtn_exp .rsbtn_exp_inner .rsbtn_dropdown {
    top: 32px !important;
}
/* Hide the listen button when playing */
.readspeaker_area .rsbtn.rsexpanded .rsbtn_play {
    display: none;
}
/* Make space for the tool toggle button */
.readspeaker_area .rsbtn.rsexpanded .rsbtn_exp {
    margin-left: 30px;
}
/* Fix height of tool toggle when playing */
.readspeaker_area .rsbtn.rsexpanded .rsbtn_tooltoggle {
    height: 28px;
    top: 0;
}
/* Round corners of tool toggle */
.readspeaker_area .rsbtn .rsbtn_tooltoggle {
    align-self: center;
    border-radius: 77px 0 0 77px;
    background-color: #D9E5FC;
    border: 0;
    cursor: pointer;
    &:hover{
        background-color: #D9E5FC;
    }
}

.rs_addtools .rsbtn_tooltoggle .rsicn::before, .rsbtn .rsbtn_tooltoggle .rsicn::before {
    content: "\22EE" !important;
    color: #06235B;
}

.readspeaker_area .rsbtn .rsbtn_exp_inner,
.rsbtn .rsbtn_closer {
    border-radius: 0 6px 6px 0 !important;
}
.readspeaker_area .rsbtn .rsbtn_play,
.rsbtn {
    border-radius: 77px;

}
/* make sure the listen button doesn't break into two lines */
.readspeaker_area .rsbtn {
    min-width: 180px;
}
/* make sure the expanded player fits */
.readspeaker_area .rsbtn.rsexpanded {
    min-width: 285px;
}
/* fix for the spacer that improves the navigation buttons somewhat */
.navbar-items .spacer {
    width: unset;
}

.rs_addtools .rsbtn_play, .rsbtn .rsbtn_play{
    background-color: #D9E5FC;
    padding: 1em 0;
    border-radius: 77px;
    border: 0;

    &:hover{
        background-color: #D9E5FC;

    }
}


.rsbtn .rsbtn_play .rsbtn_left .rsbtn_text span {
    font-family: Proxima Nova;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0em;
    color: #06235B;
    height: 100%;
    margin: 0;
    padding-left: 1.67em;
    padding-right: 0.2em;
}


.rs_addtools .rsbtn_play .rsbtn_right.rsimg, .rsbtn .rsbtn_play .rsbtn_right.rsimg{
    border-left: 0;
    padding: 0;
    color: #06235B;
    background-color: white;
    border-radius: 100%;
    padding: 0 6px;
    margin: 0 10px;
}

.rs_addtools .rsbtn_tooltoggle:focus,
.rsbtn .rsbtn_tooltoggle:focus {
    z-index: 20 !important;
    background-color: transparent !important;
}

.rs_addtools .rsbtn_tooltoggle:active, .rsbtn .rsbtn_tooltoggle:active {
    background: transparent;
}


// }
#readspeaker_area .rsbtn_toolpanel {
    z-index: 20!important;

}
